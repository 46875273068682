<template>
<div></div>
</template>

<script>
export default {
  name: "Blank",
  data(){
    return{
      searchInput:null
    }
  },
  created() {
    this.searchInput = this.$route.query.searchInput
    this.$router.push({
      path: '/searchdetails',
      query: { searchInput: this.searchInput },
    })
  },
}
</script>

<style scoped>

</style>
